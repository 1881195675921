@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@700&display=swap");

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.text-crimson {
  font-family: "Crimson Text", serif;
}
.text-anton {
  font-family: "Anton", sans-serif;
}
.text-anderson {
  font-family: "Anderson Grotesk Black", sans-serif;
  font-weight: 900;
}
.text-inter {
  font-family: "Inter", sans-serif;
}
.text-bison {
  font-family: "Bison", sans-serif;
}
.text-main-color {
  color: #6a5040 !important;
}
.text-second-color {
  color: #6a5040 !important;
}
.bg-main-color {
  background-color: #6a5040 !important;
}
.bg-button {
  background-color: #6a5040 !important;
}
.hover:bg-button {
  background-color: #5c4435 !important;
}

.rotating-text {
  animation: rotate 5s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo {
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.mouse {
  width: 25px;
  height: 45px;
  border: 1px solid #fff;
  border-radius: 60px;
  position: relative;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 30px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 30px;
  }
}
